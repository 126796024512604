import React from 'react';
import { Link } from "react-router-dom";
import { createTable, numberWithCommas } from './common';
import * as API from '../api/api';

function Search(props: any) {
    const query = decodeURIComponent(props.match.params.query as string);
    const [user, setUser] = React.useState<any | null>(null);
    const [miner, setMiner] = React.useState<any | null>(null);
    const [block, setBlock] = React.useState<any | null>(null);
    const [transaction, setTransaction] = React.useState<any | null>(null);
    const [users, setUsers] = React.useState<any | null>(null);
    const [prefix, setPrefix] = React.useState<any | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                setUser(await API.getUser(query));
            } catch(e: any) {
                setUser(null);
            }
            try {
                setMiner(await API.getMiner(query));
            } catch(e: any) {
                setMiner(null);
            }
            try {
                setBlock(await API.getBlock(query));
            } catch(e: any) {
                setBlock(null);
            }
            try {
                setTransaction(await API.getTransaction(query));
            } catch(e: any) {
                setTransaction(null);
            }
            try {
                setUsers(await API.getUsersByKey(query));
            } catch(e: any) {
                setUsers(null);
            }
            try {
                setPrefix(await API.getPrefix(query));
            } catch(e: any) {
                setPrefix(null);
            }
        })();
    }, [query]);

    let statusRows : JSX.Element[][] = [];

    if(block)
        statusRows.push([<>Block</>, <Link to={`/blocks/${block.header.id}`}>{numberWithCommas(block.header.id)}</Link>]);
    if(user)
        statusRows.push([<>User</>, <Link to={`/users/${query}`}>{query}</Link>]);
    if(miner)
        statusRows.push([<>Miner</>, <Link to={`/miners/${query}`}>{query}</Link>]);
    if(transaction)
        statusRows.push([<>Transaction</>, <Link to={`/transactions/${query}`}>{query}</Link>]);
    if(users && users.length > 0)
        statusRows.push([<>Users with key</>, <>{users.map((value : any, index : any) => <div><Link key={index} to={`/users/${value}`}>{value}</Link></div>)}</>]);
    if(prefix)
        statusRows.push([<>Prefix</>, <Link to={`/prefixes/${query}`}>{query}</Link>]);
    if(statusRows.length === 0)
        statusRows.push([<>Nothing found</>, <></>]);
    return <div>
        { createTable("Search results", statusRows, [], ["20%", "80%"]) }
    </div>;
}

export default Search;
