import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, numberWithCommas, parseTransactionId } from './common';

function User(props: any) {
    const userId = decodeURIComponent(props.match.params.id as string);
    const [user, setUser] = React.useState<any>(null);
    const [action, setAction] = React.useState<any>(null);
    const [history, setHistory] = React.useState<any[]>([]);
    const [page, setPage] = React.useState(0);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let user = await API.getUser(userId);
                setUser(user);
                try {
                    setAction(await API.getUserAction(userId));
                } catch(e: any) {
                    setAction(null);
                }
                let historyPage = Math.floor((user.operations - 1) / 100);
                setPage(historyPage);
                setHistory(await API.getUserHistory(userId, historyPage));
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, [userId]);

    if(error)
        return <p>Error: { error }</p>;
    if(!user)
        return <p>Loading...</p>;

    console.log(user);
    let userRows : JSX.Element[][] = [
        [<>User ID</>, <div>{user.id}</div>],
        [<>Creator</>, <Link to={`/users/${user.creator}`}>{user.creator}</Link>],
        [<>Tokens</>, <>{numberWithCommas(user.tokens)}</>],
        [<>Free transactions</>, <>{numberWithCommas(user.free_transactions)}</>],
        [<>Miner</>, <Link to={`/miners/${user.miner}`}>{user.miner}</Link>],
        [<>Last miner change</>, <>{user.last_miner_change}</>],
        [<>Logout</>, <>{user.logout}</>],
        [<>Last update</>, <>{user.last_update}</>],
        [<>Keys</>, <>{Object.keys(user.settings.keys).map((key : any) => <div>{key} - trust level: {(user.settings.keys[key].power)}</div>)}</>],
//        [<>Security rules</>, <>{Object.keys(user.settings.security_rules).map((rule : any) => <div>{rule} - {JSON.stringify(user.settings.security_rules[rule])}</div>)}</>],
//        [<>Spendings</>, <>{user.spendings.map((spending : any) => <div>{spending[0]} - {spending[1]}</div>)}</>],
        [<>Update action</>, <>{action ? <>Has action</> : <>No pending action</>}</>],
    ];

    let historyRows :  JSX.Element[][] = [];
    history.slice().reverse().forEach((entry, index) => {
        const transactionDetails = parseTransactionId(entry.transaction_id);
        historyRows.push([
            <div>{page * 100 + history.length - index - 1}</div>,
            <Link to={`/blocks/${entry.block_id}`}>{entry.block_id}</Link>,
            <div>0x{transactionDetails.type.toString(16)} {entry.is_outgoing ? "OUT" : "IN" }</div>,
            <div>{numberWithCommas(transactionDetails.size)}</div>,
            <Link to={`/transactions/${entry.transaction_id}`}>{`${entry.transaction_id}`}</Link>
        ]);
    })

    const lastPage = Math.floor((user.operations - 1) / 100);
    const availablePages : number[] = [];
    for(let i = lastPage; i >= 0 && i > lastPage - 104; --i) {
        availablePages.push(i);
    }

    let pages = availablePages.map((index : any) => {
        return (<Button key={index} color={page === index ? "primary" : "default"} variant="contained"  size="small" style={{margin: 4}} onClick={async () => {
            setPage(index);
            setHistory(await API.getUserHistory(userId, index));
        }}>{index}</Button>)
    });

    return <div>
        { createTable(`User ${user.id}`, userRows, [], ["20%"]) }
        <div style={{marginTop: 10}}>{ pages }</div>
        { createTable(`Transactions`, historyRows, ["Index", "Block", "Type", "Size", "Transaction"], ["10%", "10%", "10%", "15%", "55%"]) }
    </div>;
}

export default User;
