import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, numberWithCommas, parseTransactionId, Timestamp } from './common';

function Prefix(props: any) {
    const prefixId = decodeURIComponent(props.match.params.id as string);
    const [prefix, setPrefix] = React.useState<any>(null);
    const [page, setPage] = React.useState(0);
    const [transactions, setTransactions] = React.useState<string[]>([]);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let prefix = await API.getPrefix(prefixId);
                setPrefix(prefix);
                setPage(0);
                setTransactions(await API.getPrefixStorageTransactionsHistory(prefixId, 0));
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, [prefixId]);

    if(error)
        return <p>Error: { error }</p>;
    if(!prefix)
        return <p>Loading...</p>;

    let prefixRows : JSX.Element[][] = [
        [<>ID</>, <>{prefix.id}</>],
        [<>Owner</>, <Link to={`/users/${prefix.owner}`}>{prefix.owner}</Link>],
        [<>Created at</>, <Timestamp timestamp={prefix.created_at}></Timestamp>],
        [<>Allowed users</>, <>{prefix.allowed_users.map((user : any, index : number) => <Link to={`/users/${user}`}  key={index}>{user}</Link>)}</>],
        [<>Entries</>, <>{numberWithCommas(prefix.entries)}</>],
        [<>Last entry at</>, <Timestamp timestamp={prefix.last_entry_at} />],
        [<>Update power</>, <>{prefix.update_power}</>],
        [<>Use power</>, <>{prefix.use_power}</>],
    ];

    let transactionsRows :  JSX.Element[][] = [];
    let offset = page * 100;
    transactions.forEach((transaction, index) => {
        const transactionDetails = parseTransactionId(transaction);
        transactionsRows.push([
            <div key={index}>{offset + index}</div>, <div>{transactionDetails.block_id}</div>, <div>0x{transactionDetails.type.toString(16)}</div>, <div>{numberWithCommas(transactionDetails.size)}</div>,
            <Link key={index} to={`/transactions/${transaction}`}>{`${transaction}`}</Link>
        ]);
    });


    let pages = [];
    for(let index = 0; index < Math.ceil(prefix.entries / 100); ++index) {
        pages.push(<Button key={index} color={page === index ? "primary" : "default"} variant="contained"  size="small" style={{margin: 4}} onClick={async () => {
            setTransactions([]);
            setPage(index);
            setTransactions(await API.getPrefixStorageTransactionsHistory(prefixId, index));
        }}>{index}</Button>);
    }

    return <div>
        { createTable(`Prefix ${prefixId}`, prefixRows, [], ["20%", "80%"]) }
        <div style={{marginTop: 10}}>{ pages }</div>
        { createTable(`Transactions`, transactionsRows, ["Index", "Block ID", "Type", "Size", "Transaction"], ["10%", "10%", "10%", "15%", "55%"]) }
    </div>;
}

export default Prefix;
