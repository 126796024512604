import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, numberWithCommas, parseTransactionId, ShortText } from './common';

function Block(props: any) {
    const blockId = decodeURIComponent(props.match.params.id as string);
    const [block, setBlock] = React.useState<any>(null);
    const [transactions, setTransactions] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let block = await API.getBlock(blockId);
                setBlock(block);
                setPage(0);
                setTransactions((await API.getBlockTransactions(blockId, 0))['transaction_ids']);
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, [blockId]);

    if(error)
        return <p>Error: { error }</p>;
    if(!block)
        return <p>Loading...</p>;

    let blockRows : JSX.Element[][] = [
        [<>Block ID</>, <div>{numberWithCommas(block.header.id)}</div>],
        [<>Version</>, <div>{block.header.version}</div>],
        [<>Depth</>, <div>{numberWithCommas(block.header.depth)}</div>],
        [<>Skipped blocks</>, <div>{block.header.next_miners.length - 1}</div>],
        [<>Next miners</>, <div>{block.header.next_miners.map((miner : any, index : number) => <div><Link key={index} to={`/miners/${miner}`}>{miner}</Link></div>)}</div>],
        [<>Previous header hash</>, <ShortText>{block.header.prev_header_hash}</ShortText>],
        [<>Body hash</>, <ShortText>{block.header.body_hash}</ShortText>],
        [<>Signature</>, <ShortText>{block.header.signature}</ShortText>],
        [<>Miner</>, <Link to={`/miners/${block.header.miner_id}`}>{block.header.miner_id}</Link>],
        [<>Transactions</>, <div>{numberWithCommas(block.body.transactions_count)}</div>],
        [<>Transactions size</>, <div>{numberWithCommas(block.body.transactions_size)}</div>],
        [<>Transaction IDs hashes</>, <div>{block.body.transaction_ids_hashes.map((checksum : any, index : number) => <div key={index}>{checksum}</div>)}</div>],
    ];

    let transactionsRows :  JSX.Element[][] = [];
    let offset = page * 1024;
    if(page >= block.body.transaction_ids_hashes.length) {
        offset = block.body.transactions_count + (page - block.body.transaction_ids_hashes.length) * 1024;
    }
    transactions.forEach((transaction, index) => {
        const transactionDetails = parseTransactionId(transaction);
        transactionsRows.push([
            <div key={index}>{offset + index}</div>, <div>0x{transactionDetails.type.toString(16)}</div>, <div>{numberWithCommas(transactionDetails.size)}</div>,
            <Link key={index} to={`/transactions/${transaction}`}>{`${transaction}`}</Link>
        ]);
    });

    let pages = block.body.transaction_ids_hashes.map((checksum : any, index : number) => {
        return (<Button key={index} color={page === index ? "primary" : "default"} variant="contained"  size="small" style={{margin: 4}} onClick={async () => {
            setTransactions([]);
            setPage(index);
            setTransactions((await API.getBlockTransactions(blockId, index))['transaction_ids']);
        }}>{index}</Button>)
    });

    return <div>
        { createTable(`Block ${block.header.id}`, blockRows, [], ["20%"]) }
        <div style={{marginTop: 10}}>{ pages }</div>
        { createTable(`Transactions`, transactionsRows, ["Index", "Type", "Size", "ID"], ["10%", "15%", "15%", "60%"]) }
    </div>;
}

export default Block;
