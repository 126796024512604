import { BrowserRouter as Router, Route, Switch, Link, useHistory } from "react-router-dom";
import { TextField } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import './App.css';
import Logo from './logo.png';

import { API_URL } from './api/api';
import User from './views/user';
import Miner from './views/miner';
import Prefix from './views/prefix';
import Transaction from './views/transaction';
import Block from './views/block';
import Status from './views/status';
import StorageEntry from './views/storage-entry';
import Search from './views/search';
import Debug from './views/debug';

function SearchForm()
{
  let history = useHistory();

  function submitSearch(event: any) {
    event.preventDefault();
    const form = new FormData(event.target);
    let search = form.get('search') as string;
    history.push(`/search/${encodeURIComponent(search)}`);
  }

  return  <form id="searchForm" noValidate autoComplete="on" onSubmit={submitSearch}>
      <div className="search">
        <TextField name="search" id="search" variant="outlined" color="primary" label="Search" size="small" fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </div>
    </form>;
}

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Link to={'/'}>
            <img className="logo" alt="QAuth Explorer" src={Logo}></img>
          </Link>
          <SearchForm></SearchForm>
        </header>
        <div className="fullSeparator"></div>
        <main>
          <Switch>
            <Route path="/" exact component={Status} />
            <Route path="/blocks/:id" component={Block} />
            <Route path="/transactions/:id" component={Transaction} />
            <Route path="/users/:id" component={User} />
            <Route path="/miners/:id" component={Miner} />
            <Route path="/prefixes/:id" component={Prefix} />
            <Route path="/entries/:prefix/:key" component={StorageEntry} />
            <Route path="/entries/:key" component={StorageEntry} />
            <Route path="/search/:query" component={Search} />
            <Route path="/debug" exact component={Debug} />
            <Route>
              Invalid page
            </Route>
          </Switch>
        </main>
        <div className="fullSeparator"></div>
        <footer>
          LogPass ({API_URL})
        </footer>
      </Router>
    </div>
  );
}

export default App;
