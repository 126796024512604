const window_env = window['__env'];
export const API_URL = (window_env ? window_env.NODE_API_URL : process.env.REACT_APP_NODE_API_URL);

async function get(path : string) : Promise<any>
{
    if(!path.startsWith("/"))
        throw Error("API path should start with /");
    let query = await fetch(API_URL + path);
    let json = await query.json();
    if(json["error"])
        throw json["error"];
    return json;
}

export async function getStatus() : Promise<any>
{
    return get("/status");
}

export async function getLatestBlocks() : Promise<any>
{
    return get("/blocks/latest");
}

export async function getUser(userId : string) : Promise<any>
{
    return get(`/users/${userId}`);
}

export async function getUserAction(userId : string) : Promise<any>
{
    return get(`/users/${userId}/action`);
}

export async function getUserHistory(userId : string, page : number = 0) : Promise<any>
{
    return get(`/users/${userId}/history/${page}`);
}

export async function getMiner(minerId : string) : Promise<any>
{
    return get(`/miners/${minerId}`);
}

export async function getTransaction(transactionId : string) : Promise<any>
{
    return get(`/transactions/${transactionId}`);
}

export async function getBlock(blockId : number | string) : Promise<any>
{
    return get(`/blocks/${blockId}`);
}

export async function getBlockTransactions(blockId : number | string, chunk : number = 0) : Promise<any>
{
    return get(`/blocks/${blockId}/transactions/${chunk}`);
}

export async function getUsersByKey(key : string) : Promise<any>
{
    return get(`/users/key/${key}`);
}

export async function getPrefix(prefixId : string) : Promise<any>
{
    return get(`/storage/prefixes/${prefixId}`);
}

export async function getPrefixStorageTransactionsHistory(prefixId : string, page : number) : Promise<any>
{
    return get(`/storage/prefixes/${prefixId}/transactions/${page}`);
}

export async function getStorageEntry(prefix : string, key : string) : Promise<any>
{
    if(!prefix || prefix.length === 0)
        return get(`/storage/entries/${key}`);
    return get(`/storage/entries/${prefix}/${key}`);
}

export async function getDebugInfo() : Promise<any>
{
    return get(`/debug`);
}
