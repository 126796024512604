import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, numberWithCommas } from './common';

function Miner(props: any) {
    const minerId = decodeURIComponent(props.match.params.id as string);
    const [miner, setMiner] = React.useState<any>(null);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let miner = await API.getMiner(minerId);
                setMiner(miner);
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, [minerId]);

    if(error)
        return <p>Error: { error }</p>;
    if(!miner)
        return <p>Loading...</p>;

    let minerRows : JSX.Element[][] = [
        [<>Owner</>, <Link to={`/users/${miner.owner}`}>{miner.owner}</Link>],
        [<>Stake</>, <>{numberWithCommas(miner.stake)}</>],
        [<>Locked stake</>, <>{numberWithCommas(miner.locked_stake)}</>],
        [<>Name</>, <>{miner.settings.name}</>],
        [<>Address</>, <>{miner.settings.address}</>],
        [<>API</>, <>{miner.settings.api}</>],
        [<>Description</>, <>{miner.settings.description}</>],
        [<>Website</>, <>{miner.settings.website}</>],
        [<>Banned</>, <>{miner.banned ? "Yes" : "No"}</>],
    ];

    return <div>
        { createTable(`Miner ${minerId}`, minerRows, [], ["20%", "80%"]) }
    </div>;
}

export default Miner;
