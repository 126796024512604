import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, ShortText, Timestamp } from './common';
import base64url from "base64url";

function StorageEntry(props: any) {
    const entryPrefix = decodeURIComponent(props.match.params.prefix as string);
    const entryKey = decodeURIComponent(props.match.params.key as string);
    const [entry, setEntry] = React.useState<any>(null);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                console.log(entryPrefix, entryKey);
                let entry = await API.getStorageEntry(entryPrefix, entryKey);
                setEntry(entry);
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, [entryPrefix, entryKey]);

    if(error)
        return <p>Error: { error }</p>;
    if(!entry)
        return <p>Loading...</p>;

    let entryRows : JSX.Element[][] = [
        [<>ID</>, <div>{entry.id}</div>],
        [<>Prefix</>, <Link to={`/prefixes/${entry.prefix}`}>{entry.prefix}</Link>],
        [<>Key</>, <div>{entry.key}</div>],
        [<>Transaction ID</>, <Link to={`/transactions/${entry.transaction_id}`}>{entry.transaction_id}</Link>],
        [<>Committed at</>, <Timestamp timestamp={entry.committed_at} />],
        [<>Committed in block</>, <Link to={`/blocks/${entry.committed_in}`}>{entry.committed_in}</Link>],
        [<>Value (base64url)</>, <ShortText>{entry.value}</ShortText>],
    ];

    try {
        let decoded = base64url.decode(entry.value);
        let parsed = JSON.parse(decoded);
        entryRows.push([<>Value (json)</>, <pre>{JSON.stringify(parsed, null, 2)}</pre>]);
    } catch(e: any) {}

    return <div>
        { createTable(`Entry ${entryKey}`, entryRows, [], ["20%", "80%"]) }
    </div>;
}

export default StorageEntry;
