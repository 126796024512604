import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, numberWithCommas, Timestamp } from './common';

function Status(props: any) {
    const [status, setStatus] = React.useState<any>(null);
    const [latestBlocks, setLatestBlocks] = React.useState<any[]>([]);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let status = await API.getStatus();
                setStatus(status);
                let latestBlocks = await API.getLatestBlocks();
                setLatestBlocks(latestBlocks);
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, []);

    if(error)
        return <p>Error: { error }</p>;
    if(!status)
        return <p>Loading...</p>;

    let statusRows : JSX.Element[][] = [
        [<>Version</>, <div>{status.version}</div>],
        [<>Current time</>, <Timestamp timestamp={status.current_time} />],
        [<>Initialization time</>, <Timestamp timestamp={status.initialization_time} />],
        [<>Pricing</>, <div>{status.pricing}</div>],
        [<>Latest block</>, <Link to={`/blocks/${status.latest_block_id}`}>{numberWithCommas(status.latest_block_id)}</Link>],
        [<>Expected block</>, <div>{numberWithCommas(status.exptected_block_id)}</div>],
        [<>Users</>, <div>{numberWithCommas(status.users)}</div>],
        [<>Miners</>, <div>{numberWithCommas(status.miners)}</div>],
        [<>Transactions</>, <div>{numberWithCommas(status.transactions)}</div>],
        [<>Active tokens</>, <div>{numberWithCommas(status.tokens)}</div>],
        [<>Staked tokens</>, <div>{numberWithCommas(status.staked_tokens)}</div>],
        [<>Storage entries</>, <div>{numberWithCommas(status.storage.entries)}</div>],
        [<>Storage prefixes</>, <div>{numberWithCommas(status.storage.prefixes)}</div>],
        [<>Debug info</>, <Link to={'/debug'}>Go to debug info</Link>],
    ];

    let latestBlocksRows : JSX.Element[][] = latestBlocks.map((value) => {
        return ([
            <Link to={`/blocks/${value.header.id}`}>{numberWithCommas(value.header.id)}</Link>,
            <div>{numberWithCommas(value.header.depth)}</div>,
            <div>{numberWithCommas(value.body.transactions_count)}</div>,
            <div>{numberWithCommas(value.body.transactions_size)}</div>,
            <Link to={`/miners/${value.header.miner_id}`}>{value.header.miner_id}</Link>
        ]);
    }).reverse();

    return <div>
        { createTable("Blockchain status", statusRows) }
        { createTable("Latest Blocks", latestBlocksRows, ["Block ID", "Depth", "Transactions", "Transactions size", "Miner"], ["13%", "13%", "14%", "15%", "45%"]) }
    </div>;
}

export default Status;
