import React from 'react';
import { Link } from "react-router-dom";
import * as API from '../api/api';
import { createTable, numberWithCommas, ShortText, Timestamp } from './common';

const TRANSACTION_TYPES = {
    0x01: "INIT",
    0x03: "CREATE_USER",
    0x04: "SPONSOR_USER",
    0x06: "UPDATE_USER",
    0x0A: "LOCK_USER",
    0x0B: "BLOCK_USER",
    0x0E: "LOGOUT_USER",

    0x10: "TRANSFER_TOKENS",

    0x20: "CREATE_MINER",
    0x21: "UPDATE_MINER",
    0x25: "SELECT_MINER",
    0x27: "INCREASE_STAKE",
    0x28: "WITHDRAW_STAKE",

    0x50: "STORAGE_CREATE_PREFIX",
    0x51: "STORAGE_UPDATE_PREFIX",
    0x55: "STORAGE_ADD_ENTRY",

    0x80: "COMMIT",
}

function Transaction(props: any) {
    const transactionId = decodeURIComponent(props.match.params.id as string);
    const [transaction, setTransaction] = React.useState<any>(null);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let transaction = await API.getTransaction(transactionId);
                setTransaction(transaction);
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, [transactionId]);

    if(error)
        return <p>Error: { error }</p>;
    if(!transaction)
        return <p>Loading...</p>;

    let transactionRows : JSX.Element[][] = [
        [<>Type</>, <div>{TRANSACTION_TYPES[transaction.type]} (0x{transaction.type.toString(16)})</div>],
        [<>Pricing</>, <div>{transaction.pricing}</div>],
        [<>Block ID</>, <Link to={`/blocks/${transaction.block}`}>{numberWithCommas(transaction.block)}</Link>],
        [<>Committed at</>, <Timestamp timestamp={transaction.committed_at}></Timestamp>],
        [<>Committed in block</>, <Link to={`/blocks/${transaction.committed_in}`}>{numberWithCommas(transaction.committed_in)}</Link>],
        [<>User</>, <Link to={`/users/${transaction.user}`} >{transaction.user}</Link>],
//        [<>First key</>, <div>{transaction.first_key}</div>],
        [<>Size</>, <>{numberWithCommas(transaction.size)} bytes</>],
//        [<>Signatures</>, <>{transaction.signatures.map((signature : any, index : number) => <ShortText key={index}>{`${signature[0]}-${signature[1]}`}</ShortText>)}</>],
//        [<>Signature</>, <ShortText>{transaction.signature}</ShortText>],
    ];

    if(transaction.time)
        transactionRows.push([<>Initialization time</>, <>{transaction.time}</>]);
    if(transaction.public_key)
        transactionRows.push([<>Public key of new user</>, <div>{transaction.public_key}</div>]);
    if(transaction.new_user)
        transactionRows.push([<>New user</>, <Link to={`/users/${transaction.new_user}`}  >{transaction.new_user}</Link>]);
    if(transaction.new_user_id)
        transactionRows.push([<>New user ID</>, <Link to={`/users/${transaction.new_user_id}`}  >{transaction.new_user_id}</Link>]);
    if(transaction.miner)
        transactionRows.push([<>Miner</>, <Link to={`/miners/${transaction.miner}`}  >{transaction.miner}</Link>]);
    if(transaction.miner_id)
        transactionRows.push([<>Miner</>, <Link to={`/miners/${transaction.miner_id}`}  >{transaction.miner_id}</Link>]);
    if(transaction.new_miner_id)
        transactionRows.push([<>Miner</>, <Link to={`/miners/${transaction.new_miner_id}`}  >{transaction.new_miner_id}</Link>]);
    if(transaction.destination)
        transactionRows.push([<>Destination user</>, <Link to={`/users/${transaction.destination}`} >{transaction.destination}</Link>]);
    if(transaction.prefix)
        transactionRows.push([<>Prefix</>, <Link to={`/prefixes/${transaction.prefix}`} >{transaction.prefix}</Link>]);
    if(transaction.new_prefix !== undefined)
        transactionRows.push([<>Prefix</>, <Link to={`/prefixes/${transaction.new_prefix}`} >{transaction.new_prefix}</Link>]);
    if(transaction.key !== undefined)
        transactionRows.push([<>Key</>, <Link to={`/entries/${transaction.prefix ? transaction.prefix + "/": ""}${transaction.key}`} ><ShortText>{transaction.key}</ShortText></Link>]);
    if(transaction.value !== undefined) {
        if(typeof(transaction.value) === 'string') {
            transactionRows.push([<>Value</>, <ShortText>{transaction.value}</ShortText>]);
        } else {
            transactionRows.push([<>Value</>, <>{numberWithCommas(transaction.value)}</>]);
        }
    }
    if(transaction.new_transactions !== undefined)
        transactionRows.push([<>Number of new transactions</>, <>{numberWithCommas(transaction.new_transactions)}</>]);
    if(transaction.new_transactions_size !== undefined)
        transactionRows.push([<>Size of new transactions</>, <>{numberWithCommas(transaction.new_transactions_size)}</>]);
    if(transaction.users !== undefined)
        transactionRows.push([<>Number of users</>, <>{numberWithCommas(transaction.users)}</>]);
    if(transaction.tokens !== undefined)
        transactionRows.push([<>Number of tokens</>, <>{numberWithCommas(transaction.tokens)}</>]);
    if(transaction.reward !== undefined)
        transactionRows.push([<>Reward</>, <>{numberWithCommas(transaction.reward)}</>]);
    if(transaction.address)
        transactionRows.push([<>Address</>, <>{transaction.address}</>]);
    if(transaction.api)
        transactionRows.push([<>API</>, <>{transaction.api}</>]);
    if(transaction.use_power)
        transactionRows.push([<>Use power</>, <>{transaction.use_power}</>]);
    if(transaction.update_power)
        transactionRows.push([<>Update power</>, <>{transaction.update_power}</>]);
    if(transaction.allowed_users)
        transactionRows.push([<>Allowed users</>, <>{transaction.allowed_users.map((user : any, index : number) => <div key={index} >{`${user}`}</div>)}</>]);
    if(transaction.keys)
        transactionRows.push([<>New keys</>, <>{transaction.keys.map((signature : any, index : number) => <div key={index} >{`${signature[0]} - ${signature[1]}`}</div>)}</>]);

    if(transaction.new_settings) {
        transactionRows.push([<>Keys</>, <>{transaction.new_settings.keys.keys.map((key : any) => 
            <div>{key[0]} - trust level: {(key[1].power/100).toFixed(2)} - flags: {key[1].flags}</div>)}</>]);
        transactionRows.push([<>Security rules</>, <>{Object.keys(transaction.settings.security_rules).map((rule : any) => 
            <div>{rule} - {JSON.stringify(transaction.new_settings.security_rules[rule])}</div>)}</>]);
    }


    if(transaction.sponsor && transaction.sponsor.user) {
        transactionRows.push([<>Sponsor</>, <Link to={`/users/${transaction.sponsor.user}`}  >{transaction.sponsor.user}</Link>]);
        transactionRows.push([<>Sponsor key</>, <div>{transaction.sponsor.key}</div>]);
        transactionRows.push([<>Sponsor signatures</>, <>{transaction.sponsor.signatures.map((signature : any, index : number) => <
            ShortText key={index} >{`${signature[0]}-${signature[1]}`}</ShortText>)}</>]);
        transactionRows.push([<>Sponsor signature</>, <div>{transaction.sponsor.signature}</div>]);
    }

    return <div>
        { createTable(`Transaction ${transactionId}`, transactionRows, [], ["20%", "80%"]) }
    </div>;
}

export default Transaction;
