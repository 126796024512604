import { Grid } from '@material-ui/core';
import React from 'react';
import * as API from '../api/api';
import { createTable, numberWithCommas } from './common';
import Tree from 'react-d3-tree';

function Debug(props: any) {
    const [debug, setDebug] = React.useState<any>(null);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        (async () => {
            try {
                let debug = await API.getDebugInfo();
                setDebug(debug);
            } catch(e: any) {
                setError(`Error: ${e.toString()}`);
            }
        })();
    }, []);

    if(error)
        return <p>Error: { error }</p>;
    if(!debug)
        return <p>Loading...</p>;

    function getBlockChildren(levels : any, level : number, hash : string) : any[] {
        if(level >= levels.length)
            return [];

        let children : any[] = [];
        levels[level].forEach((node : any) => {
            if(node.prev_hash === hash) {
                const date = new Date(debug.blockchain.initialization_time * 1000 + node.id * 15000);
                children.push({
                    name: "Block " + node.id + (node.is_pending ? " (pending)" : "") + ` (${date.toLocaleString()})`,
                    attributes: {
                        "Depth": node.depth,
                        "Miner": node.miner,
                        "Hash": node.hash
                    },
                    children: getBlockChildren(levels, level + 1, node.hash)
                });
            }
        });
        return children;
    }

    let blocks : any[] = getBlockChildren(debug.blockchain.block_tree, 0, debug.blockchain.block_tree[0][0].prev_hash);


    let databaseDebugRows : JSX.Element[][] = [];
    for (const [column, value] of Object.entries(debug.database.columns)) {
        let levels : JSX.Element[] = [];
        let totalSize = 0;
        let totalFiles = 0;
        let totalEntries = 0;
        for(const [, details_] of Object.entries((value as any).levels)) {
            let details = details_ as any;
            let entries = 0;
            if(details.files) {
                for(const [, file_details_] of Object.entries(details.files)) {
                    let file_details = file_details_ as any;
                    entries += file_details.entries;
                }
            }
            levels.push(<>
                <Grid item xs={1}>{`${details.level}`}</Grid>
                <Grid item xs={4}>{`${numberWithCommas(details.size)} bytes`}</Grid>
                <Grid item xs={3}>{`${details.file_count} files`}</Grid>
                <Grid item xs={4}>{`${numberWithCommas(entries)} entries`}</Grid>
            </>);
            totalSize += details.size;
            totalFiles += details.file_count;
            totalEntries += entries;
        }
        levels.push(<>
            <Grid item xs={1}><b>=</b></Grid>
            <Grid item xs={4}><b>{`${numberWithCommas(totalSize)} bytes`}</b></Grid>
            <Grid item xs={3}><b>{`${numberWithCommas(totalFiles)} files`}</b></Grid>
            <Grid item xs={4}><b>{`${numberWithCommas(totalEntries)} entries`}</b></Grid>
        </>);
        databaseDebugRows.push([
            <>{column}</>,
            <><Grid container spacing={1}>{levels.map((level, index) => level)}</Grid></>
        ]);
    }

    return <div>
        <h2>Blockchain debug info</h2>
        <div id="treeWrapper" style={{ height: '2370px' }}>
          <Tree data={blocks} orientation="vertical" zoom={ 0.7 } zoomable={ false } collapsible={ false }
            depthFactor={ 100 } separation={{ nonSiblings: 4.5, siblings: 4.5 }} translate={{ x: 360, y: 20 }}/>
        </div>
        { createTable("Database debug info", databaseDebugRows, [], ["20%", "80%"]) }
    </div>;
}

export default Debug;
