import React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { Buffer } from 'buffer';

export function numberWithCommas(x : number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function createTable(title : string, rows : JSX.Element[][], cellNames : string[] = [], widths : string[] = []) {
    return <>
        <h2>{title}</h2>
        <Table style={{fontWeight: "bold"}}>
            {
                cellNames.length === 0 ? <></> :
                <TableHead>
                    <TableRow>
                        {cellNames.map((cell, id) => (
                            <TableCell key={id}>{ cell }</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            }
            <TableBody>
                {rows.map((cells, id) => (
                    <TableRow key={id}>
                        {cells.map((cell, id) => (
                            <TableCell key={id} width={widths.length > id ? widths[id] : "auto"}>{ cell }</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </>;
}

export function ShortText(props: any) {
    const size = props.size || 88;
    const [showFull, setShowFull] = React.useState(false);
    return <div onClick={() => setShowFull(!showFull)} className="shortText">{
        showFull || props.children.length <= size ? props.children : `${props.children.substr(0, size)}...`
    }</div>;
}

export function Timestamp(props: any) {
    const timestamp = props.timestamp || 0;

    if(timestamp === 0)
        return <div>Never</div>;
    
    var date = new Date(timestamp * 1000);
    return <div>{`${date.toLocaleString()}`}</div>;    
}

export function parseTransactionId(transactionId: string) {
    const buffer = Buffer.from(transactionId.replace(/-/g, '+').replace(/_/g, '/'), "base64");
    const blockId = buffer.readUInt32BE(0);
    const type = buffer.readUInt8(4);
    const size = buffer.readUInt16BE(5);
    const hash = buffer.slice(7)
    return {
        'block_id': blockId,
        'type': type,
        'size': size,
        'hash': hash
    };
}
